/*================ Global Properties Css Start Here ======== */
body {
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
  width: 100%;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

img {
  max-width: 100%;
}

* {
  margin: 0;
  padding: 0;
  outline: none !important;
}

*:focus {
  outline: none;
}

a:focus {
  outline: none !important;
  text-decoration: none !important;
}

a:hover {
  outline: none !important;
  text-decoration: none !important;
}

a:hover,
a:focus {
  color: #1c8a6f;
}

.container {
  max-width: 90%;
  width: 100%;
  position: relative !important;
}

#has-success-div {
  text-align: center;
  font-size: 28px;
  color: #60a707;
  font-weight: 600;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 30px;
  display: none;
}

#has-error-div {
  text-align: center;
  font-size: 28px;
  color: red;
  font-weight: 600;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 30px;
  display: none;
}

.align-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

/*================ Global Properties Css End Here ======== */
/*================ This IS Header Css Start Here ======== */
header {
  background: transparent;
  padding: 8px 0;
  width: 100%;
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
}

.fa-bars {
  font-size: 22px !important;
  font-weight: 300 !important;
  color: #fff;
  margin-top: 13px;
}

.whatsapp-opne-icon {
  width: 60px;
  border-radius: 100%;
  overflow: hidden;
  position: fixed;
  bottom: 25%;
  right: 20px;
  box-shadow: 0 3px 4px -2px rgb(0 0 0 / 50%);
  z-index: 999999;
  display: block;
}

img {
  max-width: 100%;
}

.main_banner_home {
  background: #ebfbf8;
  padding-bottom: 80px;
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.main_banner_right_imag {
  position: absolute;
  right: 0;
  top: 50px;
}
.main_banner_right_imag img{
  max-height:75%;
  max-width: 100%;
  margin-top:5%;
}
.home-particles canvas {
  position: absolute;
  z-index: 0;
  opacity: 0.25;
  color: #fff;
}

.align_items_fix_vh {
  height: 100vh;
  align-items: center;
  display: flex;
  /* z-index:99; */
}

.main-handing {
  font-weight: 600 !important;
  font-size: 54px;
  color: #2c2c2c;
}

.big-font-banner-column_p {
  font-size: 22px;
  color: #444;
  padding: 25px 0;
}

.home-banner-bolton {
  background: #1c8a6f;
  box-shadow: 0px 4px 10px rgb(1 95 92 / 30%);
  border-radius: 8px;
  color: #fff;
  padding: 10px 30px;
  display: inline-flex;
  font-size: 18px;
  text-transform: uppercase;
}

.home-banner-bolton:hover,
.home-banner-bolton:focus {
  background: #fff;
  color: #1c8a6f !important;
}

.tabs3-in-home-page {
  text-align: center;
  padding: 60px;
}

.tabs3-in-home-page .container {
  background: #fff;
  box-shadow: 0 7px 16px rgb(0 0 0 / 14%), 0 2px 3px rgb(0 0 0 / 7%),
    0 0 16px rgb(0 0 0 / 7%);
  padding: 60px 30px 60px 30px;
  width: 95%;
}

#window-faction {
  display: none;
}

#web-close-button {
  display: block;
}

.panding_right_col__ {
  padding-right: 20px;
  /* z-index: 5; */
}

.section_sub_hadding {
  font-size: 42px;
  color: #2c2c2c;
  padding-bottom: 10px;
}

p {
  font-size: 17px;
  color: #3b3f48;
}

.tabs3-in-home-page p {
  max-width: 960px;
  margin: 0 auto;
  font-size: 22px;
  line-height: 37px;
}

.light_greens {
  background: #ebfbf8;
}

.section_padding {
  padding: 80px 0;
}

.page_3rd_section {
  margin-top: -65px;
  padding-top: 120px;
}

/* .Learn_More_bttn {
  background: transparent;
  color: #444;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  box-shadow: none;
  margin-top: 4px;
}
.Learn_More_bttn:hover,
.Learn_More_bttn:focus {
  color: #1c8a6f;
} */
.Learn_More_bttn {
  background: #1c8a6f;
  box-shadow: 0px 4px 10px rgb(1 95 92 / 30%);
  border-radius: 8px;
  color: #fff;
  padding: 10px 25px;
  display: inline-flex;
  font-size: 16px;
  text-transform: uppercase;
}

.Learn_More_bttn:hover,
.Learn_More_bttn:focus {
  background: #fff;
  color: #1c8a6f !important;
}

.Learn_More_bttn i {
  vertical-align: middle;
  margin-top: 5px;
}

.section_image_columan {
  text-align: center;
}

.section_image_columan img {
  display: block;
  margin: 0 auto;
  padding: 0px 30px;
  max-height: 400px;
}

footer {
  background: #2c2c2c;
  padding: 45px 0 30px 0;
  color: #f1f1f1;
}

.foot-link li {
  margin-left: 10px;
  border-right: 1px solid #fff;
  padding-right: 10px;
  display: inline-block;
}

.foot-link li.last {
  border-right: none;
  padding-right: 0;
}

.foot-link li a {
  color: #fff;
  font-size: 14px;
  text-transform: inherit;
}

.foot-link {
  text-align: right;
}

.copy-right {
  text-align: left;
  font-size: 12px;
  opacity: 0.5;
  margin-top: 14px;
}

.foot-link li:last-child {
  border-right: none;
  padding-right: 0;
}

.site_disclaimer {
  font-size: 13px;
  margin-top: 15px;
}

.sub_hadding_sub_div {
  font-family: "Roboto";
  font-weight: 300;
}

.blog_list_Categories_name {
  display: block;
  color: #072c3d;
  font-weight: bold;
  font-size: 15px;
}

.small_blog_dis_box p {
  font-size: 16px;
  margin-top: 10px;
  padding-right: 5px;
}

.blog_listing_div {
  background: #fff;
  border-radius: 12px;
  box-shadow: 1px 1px 2px #ccc;
  padding: 10px 15px 0px 15px;
  margin-bottom: 25px;
  border: 1px solid #dddd;
  margin-top: 25px;
}

.big_blog_dis_box {
  text-align: left;
  padding: 25px 20px;
}

.big_blog_hadding {
  font-size: 32px;
  color: #222;
  font-weight: 600;
  line-height: 35px;
  margin-top: 6px;
  display: block;
}

.blog_listing_div a:hover,
.blog_listing_div a:focus {
  color: #1c8a6f;
}

.read_more_text {
  color: #666;
  margin-bottom: 15px;
  display: block;
}

.read_more_text:hover,
.read_more_text:focus {
  color: #1c8a6f;
}

.blog_news_post_card_box {
  background: #fff;
  box-shadow: 1px 1px 4px #bdbdbd;
  width: 100%;
  overflow: hidden;
  padding: 15px 15px 5px 15px;
  border-radius: 8px;
}

.first_blog_image img {
  align-items: center;
  width: auto;
  border-radius: 15px;
}

.first_blog_image {
  max-height: initial;
  text-align: center;
  display: block;
  overflow: hidden;
  margin-bottom: 15px;
}

.card_box_dis .big_blog_hadding {
  font-size: 19px;
  line-height: initial;
  margin-bottom: 8px;
  display: block;
}

.testimonials_section {
  background: #eee;
}

.testimonials_section .container {
  background: #fff;
  padding: 40px;
  box-shadow: #999 2px 2px 3px;
}

.clint-image-box {
  background: #fff;
  display: flex;
  align-items: center;
  height: 250px;
  text-align: center;
  width: 100%;
  border: 1px solid #aaa;
}

.clint-image-box img {
  display: block;
  margin: 0 auto;
}

.clint_feed-back-dis {
  font-weight: 600;
  color: #222;
  font-size: 20px;
}

.schedule_demo_section {
  background: #ebfbf8;
  color: #2c2c2c;
  max-width: 1170px;
  margin: 80px auto;
  border-radius: 40px;
}

.Schedule_buton_footer {
  float: right;
  background: #1c8a6f;
  border-color: #1c8a6f;
  padding: 9px 25px;
}

.main_banner_inside_page {
  background: #f2f2f2;
  padding-bottom: 60px;
}

/* .main_banner_inside_page .align_items_fix_vh {
  padding-top: 40px;
} */
.theems_color_close {
  color: #1c8a6f;
}

.theems_color_close:hover {
  color: #1c8a6f;
}

.contact_us {
  background: #fff;
  position: relative;
  align-items: center;
}

.contact_us_row_dis {
  margin-top: 100px;
}

.main_hadding_contact_us {
  font-size: 51px;
  font-family: "Roboto";
  font-weight: 600;
  color: #444;
}

.dis_contact_us_p {
  font-size: 22px;
  margin-bottom: 95px;
  font-weight: 500;
}

.ContactUS_form_input input:focus~.floating-label,
.ContactUS_form_input input:not(:focus):valid~.floating-label {
  top: -15px;
  left: 0;
  padding: 2px 8px;
  background: #fff;
  font-size: 12px;
  opacity: 1;
  font-weight: 600;
  width: 100%;
}

.ContactUS_form_input .inputText {
  height: inherit;
  width: 100%;
  display: block;
  border: none;
  padding: 10px 15px;
  font-size: 18px;
  margin-bottom: 20px;
  color: #222;
  font-weight: 500;
  background: #fff;
  border-radius: 4px;
}

.ContactUS_form_input .floating-label {
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 11px;
  transition: 0.2s ease all;
  color: #777;
  font-size: 16px;
}

.ContactUS_form_input {
  position: relative;
}

.contact_button_Submit {
  display: block;
  background: #444;
  padding: 12px 15px;
  text-align: center;
  width: 100%;
  color: #fff;
  border: none;
  font-size: 20px;
  font-weight: 500;
  margin-top: 30px;
}

.contact_button_Submit:hover {
  background: #1c8a6f;
  color: #fff;
}

.social_contact_link li a {
  font-size: 21px;
  color: #666;
}

.social_contact_link li a:hover {
  color: #1c8a6f;
}

.header_mune_ul_list {
  text-align: right !important;
}

.header_mune_ul_list li {
  display: inline-block;
  position: relative;
}

.header_mune_ul_list li a {
  color: #fff;
  font-size: 17px;
  margin-left: 20px;
}

.manue_line_button {
  display: none;
}

.header_mune_ul_list li ul {
  background: #fff;
  box-shadow: 4px 4px 10px #aaa;
  color: #222;
  position: fixed;
  right: 15px;
  top: 60px;
  border-radius: 15px;
  text-align: left;
  padding: 40px 30px 10px 30px;
  display: none;
}

.header_mune_ul_list li ul.big_list_manue {
  width: 800px !important;
}

.header_mune_ul_list li:hover ul {
  display: block;
}

.header_mune_ul_list li ul li {
  display: block;
}

.header_mune_ul_list li ul li a {
  color: #1c8a6f;
  font-weight: bold;
  display: block;
  margin-bottom: 6px;
  font-size: 17px;
}

.header_mune_ul_list li ul li a:hover {
  color: #1c8a6f;
}

.header-transparent {
  z-index: 1;
}

.header-transparent .header_mune_ul_list li a {
  color: #222;
}

.header-transparent .header_mune_ul_list li ul li a {
  color: #1c8a6f;
}

.contact_text,
.contact_text a {
  color: #666;
}

.ContactUsFormHolder {
  background: #ebfbf8;
  box-shadow: 5px 5px 10px #ddd;
  padding: 35px 25px 20px 25px;
  border-radius: 14px;
}

.newsletter_box {
  width: 36%;
  background: #f7f7f7;
  border-radius: 12px;
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 2px #ccc;
  padding: 40px 40px;
}

.big_blog_dis_box {
  text-align: left;
  padding: 25px 20px;
}

.big_blog_hadding {
  font-size: 28px;
  color: #222;
  font-weight: 600;
  line-height: 35px;
  margin-top: 6px;
  display: block;
}

.publish_date_author_name {
  font-size: 16px;
  color: #777;
  margin-top: 6px;
}

.social_media_sharing_ul li {
  display: inline-block;
  margin: 0px 9px 0px 0;
}

.social_media_sharing_ul li:last-child {
  margin-right: 0;
}

.social_media_sharing_ul li a {
  padding: 0px 9px;
  font-weight: 500;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  align-items: center;
  display: flex;
  font-size: 16px;
  background: #222;
  color: #fff;
  border-color: #fff;
}

.social_media_sharing_ul {
  clear: both;
  text-align: right;
  margin-right: 15px;
}

.social_media_sharing_ul li.facebook a:hover {
  color: #fff;
  background: #1877f2;
}

.social_media_sharing_ul li.twitter a:hover {
  color: #fff;
  background: #1b95e0;
}

.social_media_sharing_ul li.linkedin a:hover {
  color: #fff;
  background: #0073b1;
}

.social_media_sharing_ul li.whatsapp a:hover {
  color: #fff;
  background: #1bd741;
}

.blog_deatils_contents {
  padding: 60px 0;
  max-width: 950px;
  margin: 0 auto;
  margin-top: 70px;
}

.blog_deatils_contents .container {
  max-width: 100%;
}

.blog_deatils_contents .first_blog_image {
  text-align: center;
  display: flex;
  overflow: hidden;
  background: #eee;
  border-radius: 15px;
  margin: 19px 0 30px 0;
}

.blog_deatils_contents .first_blog_image img {
  display: block;
  margin: 0 auto;
  width: 100%;
  border-radius: 0;
}

.blog_deatils_contents .big_blog_hadding {
  font-size: 50px;
  line-height: initial;
}

.blog_deatils_contents .small_blog_dis_box p {
  font-size: 18px;
  margin-top: 3px;
  margin-bottom: 30px;
}

.blog_deatils_contents_dis ul li {
  list-style-type: disc;
}

.blog_deatils_contents_dis ol li {
  list-style-type: lower-roman;
}

.blog_deatils_contents_dis ul,
.blog_deatils_contents_dis ol {
  margin-left: 20px;
}

.blog_deatils_contents_dis ul li,
.blog_deatils_contents_dis ol li {
  margin-top: 8px;
  font-size: 16px;
  color: #666;
}

#blog_news_post_section {
  background: #f1f1f1;
}

.section-pading {
  padding: 90px 0;
}

.blog_news_post_card_box {
  background: #fff;
  box-shadow: 1px 1px 4px #bdbdbd;
  width: 100%;
  overflow: hidden;
  padding: 20px;
  border-radius: 8px;
}

.blog_news_post_list:last-child {
  padding-bottom: 0px;
  border-bottom: none;
  margin-bottom: 0px;
}

.blog_news_post_list {
  display: block;
  padding-bottom: 15px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 15px;
}

.similer_news_post_box .blog_news_post_list_image {
  width: 100%;
  text-align: center;
  height: 150px;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.similer_news_post_box .blog_news_post_list_image img {
  display: block;
  margin: 0 auto;
}

.card_box_dis h2 {
  font-size: 20px;
  font-weight: bold;
  color: #222;
  margin-bottom: 6px;
}

.card_box_dis .big_blog_hadding {
  font-size: 17px;
  line-height: initial;
  margin-bottom: 8px;
  display: block;
}

.align_items_social_media_sharing_ul {
  text-align: right;
}

.blog_deatils_contents_dis h1,
.blog_deatils_contents_dis h2,
.blog_deatils_contents_dis h3,
.blog_deatils_contents_dis h4,
.blog_deatils_contents_dis h5,
.blog_deatils_contents_dis h6 {
  margin-top: 10px;
  padding-bottom: 6px;
  font-weight: 600;
  color: #444;
}

.blog_banner_section {
  background-color: #f5f5f5;
  position: relative;
  overflow: hidden;
  padding: 90px 0 40px 0;
}

.blog_banner_section .main_banner_text_row {
  display: flex;
  align-items: center;
}

.blog_banner_section .main_banner_text_row h1 {
  font-size: 37px;
  margin-bottom: 6px;
  font-weight: 600;
  color: #072c3d;
}

.blog_banner_section .main_banner_text_row p {
  font-size: 21px;
  margin-top: 10px;
}

.blog_list_section_holder {
  padding: 60px 0;
}

.logo img {
  max-height: 80px;
}

.header_mune_ul_list li ul li .sub_samle_manue {
  position: static;
  margin: 10px 0 35px 40px;
  padding: 0;
  background: transparent;
  box-shadow: none;
  width: auto;
}

.header_mune_ul_list li ul li .sub_samle_manue li a {
  color: #666;
  font-size: 14px;
}

.header_mune_ul_list li ul li a i {
  font-size: 24px;
  color: #1c8a6f;
  margin-right: 5px;
  display: inline-block;
}

.header_mune_ul_list li ul li .sub_samle_manue li a:hover,
.header_mune_ul_list li ul li .sub_samle_manue li a:focus {
  color: #1c8a6f;
}

.products_deatils_page_ul {
  list-style-type: disc;
  margin-left: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.products_deatils_page_ul li {
  list-style-type: none;
  font-size: 16px;
  color: #3b3f48;
  width: 24%;
  margin: 0 0.5% 0 0.5%;
  vertical-align: top;
  border: 1px solid #eee;
  padding: 20px 25px;
  border-radius: 20px;
  display: inline-block;
  box-shadow: 2px 2px 5px #eee;
  background: #fff;
}

.big_section_hadding_text {
  font-size: 27px;
  color: #444;
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
  border: 1px solid #eee;
  padding: 50px;
  border-radius: 20px;
  display: block;
  margin-top: 50px;
  box-shadow: 4px 4px 8px #eee;
}

.sub_banner_head_row_dis {
  margin-top: 35px;
}

.sub_banner_head_row_dis .main_hadding_contact_us {
  margin-bottom: 12px;
}

.footer_row_list_li {
  text-align: left;
  border-bottom: 1px solid #fff;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.footer_listing_ul {
  margin-top: 15px;
}

.footer_listing_ul li {
  margin-bottom: 8px;
}

.footer_listing_ul li a {
  color: #fff;
  font-weight: 300;
  font-size: 14px;
}

/* .footer_listing_ul li ul{
	margin-left: 15px;
	} */
.footer_listing_ul li ul li {
  margin-top: 8px;
}

.big_call_listing_footer {
  margin-bottom: 40px !important;
}

/* .main_banner_inside_page .big-font-banner-column_p {
  color: #777;
  line-height: 34px;
} */
.main_banner_inside_page .home-banner-bolton {
  color: #fff;
}

.main_banner_inside_page .right-column-banner img {
  max-height: 75vh;
  float: right;
}

.green_active_hading {
  font-size: 16px;
  color: #1c8a6f;
  margin: 15px 0;
  text-transform: uppercase;
}

.main_banner_home .main-handing {
  color: #222;
}

.big_call_listing_footer a span {
  font-weight: 800;
  font-size: 18px;
}

.wihte_color_bg .testimonials_section {
  background: #fff;
}

.CoustmModalHolder .modal-body {
  background: #eee;
  padding: 15px;
  margin-top: 11px;
}

.CoustmModalHolder .modal-dialog {
  width: 95%;
  max-width: 500px;
  color: #000;
}

.CoustmModalHolder .modal-content {
  padding: 25px;
}

.CoustmModalHolder .modal-header {
  padding: 0;
  border-bottom: none;
}

.time_sub_row_list div {
  background: #fff;
  padding: 2px 8px;
  display: inline-block;
  font-size: 11px;
  margin-top: 10px;
  font-weight: bold;
}

.time_sub_row_list div:hover,
.time_sub_row_list .time_list_span.active {
  cursor: pointer;
  background: #1c8a6f;
  color: #fff;
}

.time_sub_row_list .disabled_time_boked {
  background: #ddd;
  color: #666;
  cursor: not-allowed !important;
}

.time_sub_row_list .disabled_time_boked:hover,
.time_sub_row_list .disabled_time_boked.active {
  background: #ddd;
  color: #666;
  cursor: not-allowed !important;
}

.CoustmModalHolder .modal-content h2 {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 10px;
}

.CoustmModalHolder table th {
  font-size: 9px;
  color: #444;
}

.CoustmModalHolder table th b {
  font-size: 27px;
  color: #000;
  margin-right: 5px;
  display: block;
  margin-top: -10px;
}

.ThankYoutModal {
  text-align: center;
}

.ThankYou_body_text {
  padding: 30px 30px;
  text-align: center;
  color: #1c8a6f;
}

button.close {
  font-size: 32px;
  font-weight: 300;
  position: absolute;
  right: 20px;
  top: 10px;
}

.section_dis_columan p {
  font-size: 17px;
  color: #3b3f48;
  margin: 10px 0 35px 0;
}

.align-items .section_dis_columan {
  padding-left: 50px !important;
}

.align-items.column-reverse .section_dis_columan {
  padding-left: 0;
}

.sub_banner_head_row_dis .section_image_columan {
  padding-left: 50px;
}

.main_banner_inside_page .right-column-banner {
  padding-left: 70px;
}

.align-items.column-reverse .section_image_columan {
  padding-left: 50px;
}

.main_points_hadings {
  display: block;
  font-size: 25px;
  font-weight: 500;
  color: #2c2c2c;
  margin-bottom: 5px;
}

.sub_banner_head_row_dis .section_image_columan {
  text-align: right !important;
}

.sub_banner_head_row_dis .section_image_columan img {
  padding: 0 0 0 45px;
}

.bottom_pading_only_section {
  padding-bottom: 60px;
}

.onclick-nab_button {
  float: right;
  max-width: 25px;
}

.ChildMainBanner_holder {
  padding-bottom: 0;
}

.green_hader_bg {
  background: #1c8a6f;
  box-shadow: 0 2px 10px #1c8a6f;
}

.fixed-header-class {
  box-shadow: 0 2px 10px #999;
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
}

.green_hader_bg .logo img,
.fixed-header-class .logo img {
  max-width: inherit;
  max-height: 50px;
}

.green_hader_bg .header_mune_ul_list li a,
.fixed-header-class .header_mune_ul_list li a {
  font-size: 14px;
}

.green_color_lable_mob_nav_lable {
  color: #1c8a6f;
  font-size: 17px;
}

.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 20px !important;
  font-weight: 500;
}

.navigation-menu {
  width: 100% !important;
  height: auto !important;
}

.author_name_a a {
  color: #777;
}

.ParentMainBanner .right-column-banner img,
.ChildMainBanner_holder .section_image_columan img {
  max-height: 450px !important;
}

.ParentMainBanner,
.ParentMainBanner .main-handing,
.ParentMainBanner p {
  background: #ebfbf8;
  padding: 10px 0 15px 0;
}

.copy_right_algin_right {
  text-align: right;
}

.navigation-menu {
  width: 100% !important;
  min-height: 100%;
  height: auto !important;
  max-height: initial;
}

.Arte_Sape_header {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  overflow: visible;
  height: 80vh;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 100%;
  object-position: 50% 100%;
  width: 100%;
  max-width: 100%;
}

.min_hader_page {
  background: transparent;
  position: static;
}

.ScheduleDemoPage_P {
  font-size: 24px;
}

.ScheduleDemoPage_ul li {
  width: 100%;
}

.ScheduleDemoFormHolder {
  background: #fff;
  color: #222;
  padding: 45px;
  border-radius: 45px;
  margin-top: 40px;
}

.ScheduleDemoFormHolder h2 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 52px;
  font-weight: 600;
}

.ScheduleDemoFormHolder .ContactUS_form_input .inputText {
  padding: 12px 20px;
  font-size: 15px;
  margin-bottom: 25px;
  border: 1px solid #d6d6db;
  color: #888e9a;
}

.errorInput {
  border: 1px solid red !important;
}

.ScheduleDemoFormHolder .contact_button_Submit {
  display: block;
  padding: 14px 15px;
  background: #1c8a6f;
  box-shadow: 0px 4px 10px rgb(1 95 92 / 30%);
  border-radius: 8px;
}

/* .ScheduleDemoFormHolder .contact_button_Submit:hover
.ScheduleDemoFormHolder .contact_button_Submit:focus
 {
  background: #fff;
  color: #1c8a6f !important;
}
*/
.CookieConsent {
  background: rgb(235 251 248) !important;
  box-shadow: 0 2px 10px #000;
  color: #000 !important;
  font-size: 14px;
}

.CookieConsent button {
  background: #1c8a6f !important;
  color: #fff !important;
}

.login_button {
  background: #1c8a6f;
  box-shadow: 0px 4px 10px rgb(1 95 92 / 30%);
  border-radius: 8px;
  color: #fff !important;
  padding: 4px 22px;
  display: inline-flex;
}

.green_tex_spa {
  font-weight: bold;
  color: #1c8a6f;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
}

.PatternLineiIcon {
  position: absolute;
  left: 35%;
  top: -60px;
}

.home-banner-bolton i {
  vertical-align: middle;
  margin-top: 6px;
}

.ElementDocdedbannerIconBanner {
  position: absolute;
  left: 0;
  top: 25%;
}

.prakey_solving_section {
  padding: 90px 0;
  text-align: center;
}

.setion_main_hadding {
  font-size: 45px;
  color: #2c2c2c;
}

.soliving_dis_col_box {
  text-align: left;
  border: 1px solid #ddd;
  padding: 35px 40px 40px 40px;
  border-radius: 20px;
  display: block;
  margin-top: 50px;
  box-shadow: 2px 3px 2px #bbb;
}

.soliving_dis_col_box .section_sub_hadding {
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #2c2c2c;
}

.soliving_dis_col_box p {
  font-size: 15px;
}

.soliving_dis_col_box .Learn_More_bttn {
  font-size: 16px;
  color: #1c8a6f;
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.soliving_dis_icon {
  margin-bottom: 10px;
  display: inline-block;
}

.solving_colo_box_3 {
  width: 29.5%;
}

.row_prakey_solving_box_points {
  margin-bottom: 80px;
}

.illust_left_col_box {
  margin-left: -12%;
}

.illust_rigt_col_box {
  margin-right: -12%;
}

.helping_practice_section .setion_main_hadding {
  font-weight: 500;
  font-size: 50px;
  line-height: 65px;
}

.helping_practice_section p {
  margin: 15px 0 25px 0;
}

.Our_Blog_section {
  background: #ebfbf8;
  position: relative;
}

.home_page_blog_box {
  text-align: left;
  padding: 0;
}

.home_page_blog_box .blog_image {
  border-radius: 20px;
  display: block;
  overflow: hidden;
  margin-bottom: 11px;
  box-shadow: 1px 2px 3px #c7baba;
}

.home_page_blog_box .blog_image img {
  width: 100%;
}

.home_page_blog_box .big_blog_hadding {
  font-size: 18px;
  color: #222;
  font-weight: 600;
  line-height: 24px;
  margin-top: 4px;
  display: block;
}

.our_blog_docted {
  top: 90px;
}

.Our_Blog_section .row {
  position: relative;
  z-index: 1;
  margin-right: -15%;
}

.TestimonialClientsHolder {
  text-align: center;
}

.slider-items.owl-carousel.BlogsHomeSilder .owl-dots {
  display: none;
}

.slider-items.owl-carousel.BlogsHomeSilder .owl-nav button {
  background: #d7f6f1;
  color: #3b3f48;
  font-size: 38px;
  padding: 6px 24px !important;
  font-weight: bold;
  border-radius: 6px;
  margin-right: 30px;
}

.BlogsHomeSilder .owl-nav {
  position: absolute;
  left: -52%;
  top: 70%;
}

.slider-items.owl-carousel.BlogsHomeSilder .owl-nav button:hover {
  background: #1c8a6f;
  color: #fff;
}

.blog_date {
  font-size: 14px;
  color: #777;
}

.blog_home_box_dis .Learn_More_bttn {
  font-size: 13.5px;
  margin-top: 15px;
  color: #1c8a6f;
  padding: 0;
  box-shadow: none;
  background: transparent;
}

.TestimonialClientsHolder {
  background: no-repeat;
  background-position: left center;
  padding-bottom: 40px;
}

.TestimonialClientsHolder .setion_main_hadding {
  margin-bottom: 20px;
}

.TestimonialClient_colo_box {
  text-align: left;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.09);
  border-radius: 20px;
  padding: 40px 0 30px 40px;
  margin: 30px 0;
  position: relative;
  min-height: 335px;
}

.TestimonialClientImage {
  width: 50px;
  height: 50px;
}

.TestimonialClientTitle {
  margin-top: -52px;
  }

.TestimonialClientTitle h4 {
  font-size: 20px;
}

.TestimonialClientTitle h5 {
  color: #8d8d8d;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 300;
}

.TestimonialClientDis {
  margin-top: 20px;
}

.VectorQuotesIocns {
  width: 35px;
  display: inline-block;
  position: absolute;
  right: 40px;
  top: 40px;
}

.VectorQuotesIocns img {
  max-width: 100%;
  width: 100%;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #1c8a6f !important;
}

.owl-theme .owl-dots .owl-dot span {
  background: #d7f6f0 !important;
}

.TestimonialClientsHolder .owl-nav {
  display: none;
}

.ParentMainBanner .main_banner_right_imag {
  position: absolute;
  right: 0;
  top: 50px;
  height: 68vh;
  display: flex;
  align-items: center;
}

.ParentMainBanner .main_banner_right_imag img {
  max-height: 400px;
}

.ParentMainBanner .align_items_fix_vh {
  height: 60vh;
}

.ParentMainBanner {
  padding: 60px 0;
}

.ligt_green_bg {
  background: #ebfbf8;
  width: 100%;
  background-position: right;
  position: absolute;
  height: 100%;
  z-index: -1;
  border-radius: 20px;
}

.right_side_bg {
  right: -10%;
}

.ligt_green_bg_col_row {
  padding: 40px 0;
}

.left_side_bg {
  left: -10%;
}

.padding_bottom_0 {
  padding-bottom: 10px;
}

.padding_top_0 {
  padding-top: 0;
}

.products_deatils_li_points {
  max-height: 62px;
}

.section_products_deatils_points {
  padding: 0 0 40px 0;
}

.ChildSubParentMainBanner .section_image_columan,
.ChildSubParentMainBanner .section_image_columan img {
  padding: 15px 0 0 0 !important;
  float: right;
}

.ParentMainBannerRequestDemo {
  height: auto;
  padding-top: 100px;
}

.ParentMainBannerRequestDemo .align_items_fix_vh {
  height: auto;
}

.green_text {
  color: #1c8a6f;
}

.BannerRequestDemoRightImage img {
  max-height: 550px !important;
  margin-top: 180px;
}

.book-Book_SlotDrBox {
  margin-bottom: 20px;
  margin-top: 25px;
}

.clander_main_box_holder span {
  display: block;
}

.WeeksDaysBox {
  text-decoration: none;
  text-align: center;
}

.WeeksDaysLabelName {
  font-size: 15px;
  text-decoration: none;

  color: #868686;
  background: #f2f2f2;
  width: 70px;
  border-bottom: 1px solid #cccccc;
  padding: 6px 0px;
}

.DateLabelName {
  font-size: 20px;
  color: #2c2c2c;
  font-weight: 600;
  display: inline-block;
  padding-top: 3px;
}

.MonthsLabelName {
  font-size: 16px;
  color: #2c2c2c;
  padding-bottom: 10px;
}

.clander_main_box_holder {
  max-width: max-content;
  margin: 25px auto 0 auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.Date_lable_hadding {
  font-size: 23px;
  margin: 40px 0 0px 0;
  font-weight: 600;
}

.WeeksDaysBox:hover .WeeksDaysLabelName,
.WeeksDaysBox:hover .MonthsLabelName,
.WeeksDaysBox:hover .DateLabelName,
.WeeksDaysBox.active .WeeksDaysLabelName,
.WeeksDaysBox.active .MonthsLabelName,
.WeeksDaysBox.active .DateLabelName {
  background: #1c8a6f;
  color: #fff;
}

.react-responsive-modal-modal {
  border-radius: 20px;
  max-width: 600px !important;
}

.d_a_t_card_box {
  text-align: center;
}

.time_lable_hadding {
  margin: 40px 0 0 0;
  padding: 0;
  font-size: 23px;
  font-weight: 600;
}

.disabled-slot {
  background: #c6c6c6 !important;
  cursor: not-allowed !important;
}

.time_box_shlots {
  background: #fafafa;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;
  color: #3b3f48;
  padding: 8px 20px;
  font-size: 18px;
  font-weight: 600;
  /* display: block; */
  display: inline-flex;
  margin: 3px;
}

.time_box_shlots:hover,
.time_box_shlots.active {
  background: #ebfbf8;
}

.modal_box_main_holder .contact_button_Submit {
  display: block;
  border-radius: 8px;
    padding: 5px 0;
    background: #1c8a6f;
    box-shadow: 0px 4px 10px rgb(1 95 92 / 30%);
    border-radius: 8px;
    margin: auto;
}

.statusActionImage {
  margin: 0 auto;
  display: block;
  text-align: center;
}

.time_lable_hadding {
  margin: 0px 0 0 0;
  line-height: initial;
}

.errorMessgeModal .time_lable_hadding {
  margin: 0px 0 0 0;
  line-height: initial;
}

.max_width_button {
  max-width: fit-content !important;
  display: flex !important;
}
.home_page_blog_box img{
  border-radius:6px;
  overflow: hidden;
  margin-bottom:10px;
}

/* @@@@@@@@@ media CSS Start Here @@@@@@@@@@ */
@media (max-width: 767px) {
  .main_banner_home{
    text-align: center;
  }
  .PatternLineiIcon{
    display: none;
  }
  .modal_box_main_holder .contact_button_Submit {
    display: block;
    padding: 10px 11px;
    margin: auto;
  }

  .time_box_shlots {
    border-radius: 6px;
    padding: 6px 15px;
    font-size: 14px;
    font-weight: 600;
    display: inline-flex;
    margin: 3px;
  }

  .WeeksDaysLabelName {
    font-size: 11px;
    width: 38px;
    padding: 5px 0px;
  }

  .DateLabelName {
    font-size: 15px;
  }

  .MonthsLabelName {
    font-size: 11px;
  }

  .react-responsive-modal-modal {
    padding: 10px !important;
  }

  .Date_lable_hadding {
    font-size: 18px;
    line-height: initial;
  }

  .time_lable_hadding {
    font-size: 20px;
  }

  .card-view-box.d_a_t_card_box p {
    font-size: 12px;
  }

  .ScheduleDemoFormHolder {
    color: #222;
    border-radius: 20px;
    margin-top: 0;
    padding: 20px;
  }

  .ScheduleDemoFormHolder h2 {
    font-size: 36px;
  }

  .ScheduleDemoFormHolder .ContactUS_form_input .inputText {
    padding: 8px 15px;
    font-size: 13px;
    margin-bottom: 20px;
  }

  .ScheduleDemoFormHolder .contact_button_Submit {
    margin-top: 10px;
    padding: 10px 12px;
    font-size: 15px;
  }

  .BannerRequestDemoRightImage img {
    max-height: 400px !important;
    margin-top: 0;
  }

  .ChildSubParentMainBanner .section_image_columan,
  .ChildSubParentMainBanner .section_image_columan img {
    padding: 0 !important;
    float: none;
  }

  section {
    width: 100%;
    overflow-x: hidden;
  }

  .right_side_bg {
    right: -15%;
  }

  .seo_section_page .seo_iamge_clip {
    margin-top: 40px;
  }

  .blog_home_box_dis .Learn_More_bttn {
    font-size: 12px;
    margin-top: 10px;
    color: #1c8a6f;
  }

  .ParentMainBanner .main_banner_right_imag {
    position: static;
  }

  .helping_practice_section {
    padding-top: 0 !important;
  }

  .home-banner-bolton i {
    margin-top: 0;
  }

  .VectorQuotesIocns {
    width: 30px;
    right: 25px;
    top: 40px;
  }

  .TestimonialClient_colo_box {
    border-radius: 13px;
    padding: 20px 20px 20px 20px;
    margin: 20px 0;
  }

  .schedule_demo_section {
    margin: 0px auto 40px auto;
    border-radius: 20px;
    max-width: 90%;
  }

  .TestimonialClientTitle {
    margin-top: -50px;
    padding-left: 68px;
  }

  .Our_Blog_section .row {
    margin: auto;
  }

  .helping_practice_section .setion_main_hadding {
    font-weight: 500;
    font-size: 34px;
    line-height: initial;
    margin-top: 15px;
  }

  .prakey_solving_section {
    padding: 30px 0;
  }

  .illust_rigt_col_box {
    margin-top: 25px;
  }

  .illust_left_col_box {
    margin-left:0%;
    width: 100%;
    margin-top: 30px;
  }

  .solving_colo_box_3 {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .soliving_dis_col_box {
    padding: 25px 30px 30px 30px;
    border-radius: 18px;
    margin-top: 30px;
  }

  .row_prakey_solving_box_points {
    padding: 0;
    margin: 0 0 40px 0;
  }

  .container {
    max-width: 100%;
    padding: 0 15px 0 15px;
  }

  .setion_main_hadding {
    font-size: 36px;
  }

  .PatternLineiIcon {
    position: absolute;
    left: -14%;
    top: -37px;
    max-width: 78px;
  }

  .main_banner_right_imag {
    position: static;
    right: 0;
    top: 0;
  }

  .main_banner_home {
    height: inherit;
  }

  .green_hader_bg .logo img,
  .fixed-header-class .logo img {
    max-width: inherit;
    max-height: 35px;
  }

  .copy_right_algin_right {
    text-align: center;
  }

  .big_call_listing_footer a span {
    font-weight: 600;
    font-size: 13px;
  }

  .big_call_listing_footer {
    margin-bottom: 20px !important;
  }

  .footer_row_list_li .col-sm-3,
  .footer_row_list_li .col-sm-4,
  .footer_row_list_li .col-sm-2 {
    width: 49%;
  }

  .logo img {
    max-width: 100%;
    max-height: 45px;
  }

  .tabs3-in-home-page p {
    font-size: 18px;
    line-height: 28px;
  }

  .tabs3-in-home-page {
    margin-top: -112px;
    text-align: center;
    padding: 0 20px;
  }

  .main_points_hadings {
    font-size: 17px;
  }

  .footer_listing_ul li a {
    color: #fff;
    font-size: 12px;
    line-height: initial;
  }

  .footer_listing_ul li ul li {
    margin-top: 6px;
  }

  .ChildMainBanner_holder {
    padding-bottom: 5px !important;
  }

  .products_deatils_page_ul li {
    font-size: 14px;
    margin-bottom: 4px;
    width: 100%;
  }

  .align-items.column-reverse .section_image_columan {
    padding-left: 0;
  }

  .main_banner_inside_page .right-column-banner {
    padding-left: 0;
  }

  .align-items .section_dis_columan {
    padding-left: 15px !important;
  }

  .section_dis_columan p {
    font-size: 16px;
    margin: 5px 0 15px 0;
    line-height: 26px;
  }

  .footer_row_list_li {
    text-align: left;
  }

  .green_active_hading {
    font-size: 15px;
  }

  .products_deatils_page_ul {
    text-align: left;
    margin-left: 15px;
  }

  .sub_banner_head_row_dis {
    margin-top: 45px;
    text-align: center;
  }

  .sub_banner_head_row_dis br {
    display: none;
  }

  .sub_banner_head_row_dis .main_hadding_contact_us {
    margin-bottom: 6px;
  }

  .products_deatils_page_ul li {
    font-size: 14px;
    margin-bottom: 25px;
  }

  .products_deatils_page_ul li:last-child {
    margin-bottom: 0;
  }

  .big_section_hadding_text {
    font-size: 20px;
    line-height: 27px;
    margin: 0 auto;
  }

  .blog_banner_section {
    display: none;
  }

  .manue_line_button {
    display: block;
  }

  .header_mune_ul_list {
    display: none;
  }

  .blog_list_section_holder {
    padding: 40px 0;
  }

  .blog_deatils_contents {
    margin-top: 45px;
  }

  .social_media_sharing_ul {
    text-align: left;
  }

  .social_media_sharing_ul li a {
    padding: 0px 9px;
    border-radius: 100%;
    display: flex;
    font-size: 14px;
  }

  .similer_blog_post_section {
    padding: 0;
  }

  .blog_deatils_contents_dis h1,
  .blog_deatils_contents_dis h2,
  .blog_deatils_contents_dis h3,
  .blog_deatils_contents_dis h4,
  .blog_deatils_contents_dis h5,
  .blog_deatils_contents_dis h6 {
    font-size: 18px;
  }

  .blog_deatils_contents {
    padding: 30px 0;
  }

  .blog_deatils_contents .big_blog_hadding {
    font-size: 22px;
    line-height: 28px;
  }

  #web-close-button {
    display: none;
  }

  #window-faction {
    display: block;
  }

  .column-reverse {
    flex-direction: column-reverse;
    padding: 0;
    margin: 0;
  }

  .main_banner_home {
    padding: 30px 0 40px 0;
    height: auto;
  }

  .align_items_fix_vh {
    padding-top: 30px;
    height: auto;
  }

  .main-handing {
    font-size: 42px;
    text-align: center;
  }

  .big-font-banner-column_p {
    font-size: 17px;
    text-align: center;
    padding: 8px 0;
  }

  .home-banner-bolton {
    padding: 8px 20px;
    margin: 25px auto 0 auto;
    font-size: 14px;
    display: block;
    text-align: center;
    max-width: max-content;
  }

  .home_page_blog_box .big_blog_hadding {
    font-size: 14px;
  }

  .blog_date {
    font-size: 13px;
  }

  .tabs3-in-home-page .container {
    padding: 25px 40px 20px 40px;
  }

  .section_sub_hadding {
    font-size: 24px;
    padding-bottom: 6px;
  }

  p {
    font-size: 14px;
  }

  .section_image_columan img {
    padding: 0 0 15px 0;
  }

  .section_dis_columan {
    text-align: center;
  }

  .Learn_More_bttn {
    font-size: 14px;
    margin-top: 2px;
  }

  .section_padding {
    padding: 40px 0;
  }

  .page_3rd_section {
    padding-top: 95px;
  }

  .testimonial-slid .align-items {
    display: block;
    width: 100%;
  }

  .testimonials_section .container {
    padding: 15px;
    margin: 0 20px;
    width: 90%;
    display: block;
    padding-bottom: 40px;
  }

  .clint-image-box {
    display: block;
    height: auto;
    margin-bottom: 15px;
  }

  .clint_feed-back-dis {
    font-size: 15px;
    text-align: center;
  }

  .owl-theme .owl-nav.disabled+.owl-dots {
    bottom: -25px;
  }

  .schedule_demo_section {
    text-align: center;
  }

  .schedule_demo_section h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .schedule_demo_section .align-items {
    display: block;
  }

  .Schedule_buton_footer {
    float: none;
  }

  .logo-image img {
    max-width: 159px;
    margin: 0 auto;
    display: block;
  }

  .video-clip-image3 {
    max-height: 200px;
    margin-bottom: 20px;
  }

  footer {
    text-align: center;
    padding: 20px 0 20px 0;
  }

  footer img {
    max-width: 150px;
    margin: 0 auto;
  }

  .copy-right {
    text-align: center;
    font-size: 10px;
  }

  footer .align-items {
    display: block;
  }

  .foot-link li a {
    font-size: 11px;
  }

  .foot-link li {
    float: none;
    border-right: none;
    display: block;
    font-size: 11px;
    margin: 8px 0 0 0;
    padding: 0;
  }

  footer {
    text-align: center;
  }

  .foot-link {
    float: none;
    margin: 20px 0 12px 0px;
    text-align: center;
  }

  .whatsapp-opne-icon {
    width: 45px;
    bottom: 30px;
  }

  .big_blog_hadding {
    font-size: 18px;
    line-height: inherit;
  }

  .blog_news_post_card_box {
    margin-bottom: 20px;
    padding: 15px 10px 30px 15px;
  }

  .Our_Blog_section {
    padding-bottom: 20px;
    text-align: center;
  }

  .contact_us_row_dis {
    margin-top: 90px;
    text-align: center;
    display: block;
  }

  .main_hadding_contact_us {
    font-size: 28px;
  }

  .dis_contact_us_p {
    font-size: 16px;
    margin-bottom: 14px;
  }

  .ContactUS_form_input .inputText {
    padding: 8px 15px;
    font-size: 17px;
  }

  .ContactUS_form_input .floating-label {
    font-size: 16px;
    top: 10px;
  }

  .ContactUsFormHolder {
    margin-top: 40px;
  }

  .ContactUS_form_input input:focus~.floating-label,
  .ContactUS_form_input input:not(:focus):valid~.floating-label {
    text-align: left;
    font-size: 10px;
  }

  .contact_button_Submit {
    font-size: 16px;
    padding: 10px 15px;
  }

  .light_greens_about_us .align-items {
    display: block;
  }

  .main_banner_inside_page {
    height: auto;
    padding-bottom: 0px;
    padding-top: 50px;
  }

  .main_banner_inside_page .align_items_fix_vh {
    padding-top: 40px;
    height: auto;
  }

  .panding_right_col__ {
    padding: 0 7px;
    margin-top: 25px;
  }

  .main_banner_inside_page .big-font-banner-column_p {
    line-height: 27px;
  }

  .small_blog_dis_box {
    text-align: left;
  }

  .card_box_dis {
    text-align: left;
  }

  .blog_listing_div {
    padding: 15px 0 5px 15px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .CoustmModalHolder .modal-content h2 {
    font-size: 25px;
  }

  .CoustmModalHolder .modal-content {
    padding: 15px;
  }

  .button.close {
    font-size: 24px;
    right: 15px;
  }
}

.main-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customOverlay {
  background: rgba(36, 123, 160, 0.7);
}

.customModal {
  max-width: 640px !important;
  width: auto;
  border-radius: 20px;
  padding: 30px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.react-responsive-modal-closeButton svg path {
  fill: #777;
}

.overlay-menu {
  z-index: 1000 !important;
}

.navigation-menu {
  width: 100% !important;
}

.overlay-menu__overlay {
  width: 100%;
  background-color: #fff;
}

.overlay-menu__wrap {
  width: 100%;
  background-color: #fff;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
  color: #1c8a6f;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
  background-color: #fff !important;
}

.green {
  color: #1c8a6f !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper .pro-arrow {
  border-color: #1c8a6f !important;
  border-width: 0 3px 3px 0 !important;
}

.sub_samle_manue .pro-item-content a {
  font-size: 17px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding: 0px 30px 4px 15px !important;
}

.cookie-link {
  color: #1c8a6f !important;
  text-decoration: underline;
}

.term_condition {
  text-transform: capitalize;
  text-align: center;
}


/* Hide Input Number Arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
/* Hide Input Number Arrows */


.main_banner_inside_page {
  padding: 86px 0 60px 0;
}

select.minimal {
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

select.minimal:focus {
  background-image:
    linear-gradient(45deg, green 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, green 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}


select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}